import logo from './assets/logo-1024-tp.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Coming late March!
        </p>
        <a
          href="mailto:lawnguru1llc@gmail.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Email
        </a>
      </header>
    </div>
  );
}

export default App;
